import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'

export default class extends Controller {
  static targets = [ 'slider' ]

  connect() {
    console.log(this.sliderTarget)
    const swiper_releases = new Swiper(this.sliderTarget, {
      slidesPerView: 1,
      spaceBetween: 40,
      breakpoints: {
        768: {
          slidesPerView: 2,
        },
        992: {
          slidesPerView: 4,
        },
      },
    })

  }

}

